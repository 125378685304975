export const BrandData = [
  {
    id: 1,
    name: "قيد الانتظار",
  },
  {
    id: 2,
    name: "في الشحن",
  },
  {
    id: 3,
    name: "تم التوصيل",
  },
  {
    id: 4,
    name: "مقبول",
  },
  {
    id: 4,
    name: "غير مقبول",
  },
];
export const ProductColors = {
  BLUE: "#0A8FDC",
  GREY: "#8781BD",
  PARROT: "#A3D39C",
  LIGHT_PINK: "#F5989D",
  PINK: "#F69679",
};

export const IdealFor = [
  {
    id: 1,
    name: "Couple",
  },
  {
    id: 2,
    name: "Men",
  },
  {
    id: 3,
    name: "Women",
  },
  {
    id: 4,
    name: "ALL",
  },
];

export const addresses = [
  {
    id: 1,
    name: "Crema User",
    mobile: "+1 508-966-0591",
    addressLine: "777 Brockton Avenue",
    city: "Abington MA",
    pin: "2351",
  },
  {
    id: 2,
    name: "Crema User",
    mobile: "+1 508-966-0592",
    addressLine: "250 Hartford Avenue",
    city: "Bellingham MA",
    pin: "2322",
  },
  {
    id: 3,
    name: "Crema User",
    mobile: "+1 508-966-0593",
    addressLine: "700 Oak Street",
    city: "Brockton MA",
    pin: "2301",
  },
];

export const DiscountList = [
  {
    id: 1,
    name: "60% or more",
  },
  {
    id: 2,
    name: "50% or more",
  },
  {
    id: 3,
    name: "40% or more",
  },
  {
    id: 4,
    name: "30% or more",
  },
  {
    id: 5,
    name: "20% or more",
  },
  {
    id: 6,
    name: "10% or more",
  },
];

export const cartItems = [
  {
    id: 1,
    title: "Mens's Exclusive Watch",
    mrp: "120",
    discount: "25",
    brand: "FastTrack",
    image: "https://via.placeholder.com/450x575ommerce/watch1.png",
    count: 1,
  },
  {
    id: 2,
    title: "Limited Edition Men's Watch",
    mrp: "125",
    discount: "20",
    brand: "FastTrack",
    image: "https://via.placeholder.com/130x215",
    count: 1,
  },
  {
    id: 3,
    title: "Unisex Special Watch",
    mrp: "100",
    discount: "30",
    brand: "FastTrack",
    image: "https://via.placeholder.com/130x215",
    count: 1,
  },
];

const ecommerceData = [
  {
    id: 1,
    title: "Mens's Exclusive Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "120",
    discount: "25",
    rating: 5,
    ideaFor: 1,
    brand: 1,
    color: ProductColors.BLUE,
    reviews: 2000,
    image: [
      {
        id: 3332,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 43554,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 434324,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 33245,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 2,
    title: "Limited Edition Men's Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "125",
    brand: 4,
    ideaFor: 3,
    discount: "20",
    rating: 4,
    color: ProductColors.PINK,
    reviews: 4356,
    image: [
      {
        id: 3,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 43,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 23,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 54,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 3,
    title: "Unisex Special Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "100",
    ideaFor: 4,
    brand: 1,
    discount: "30",
    rating: 4,
    color: ProductColors.BLUE,
    reviews: 235,
    image: [
      {
        id: 4343,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 323243,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 325432,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 653323,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 4,
    title: "Special Female's Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "100",
    discount: "35",
    rating: 4,
    ideaFor: 2,
    brand: 4,
    color: ProductColors.PINK,
    reviews: 4323,
    image: [
      {
        id: 4343443,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 324324,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 655434,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 243443,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 5,
    title: "Mens's Exclusive Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "120",
    ideaFor: 1,
    brand: 1,
    discount: "25",
    rating: 3,
    color: ProductColors.GREY,
    reviews: 2000,
    image: [
      {
        id: 43434345,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 44343,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 5654443,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 433343,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 6,
    title: "Limited Edition Men's Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "125",
    discount: "20",
    ideaFor: 3,
    brand: 3,
    rating: 3,
    color: ProductColors.LIGHT_PINK,
    reviews: 4356,
    image: [
      {
        id: 4354,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 2343,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 233,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 544,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 7,
    title: "Unisex Special Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "100",
    ideaFor: 4,
    brand: 2,
    discount: "30",
    rating: 4,
    color: ProductColors.PINK,
    reviews: 235,
    image: [
      {
        id: 4344343,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 32443243,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 32544432,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 654333323,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 8,
    title: "Special Female's Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "100",
    discount: "35",
    ideaFor: 2,
    brand: 1,
    rating: 2,
    color: ProductColors.PARROT,
    reviews: 4323,
    image: [
      {
        id: 344,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 4342,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 7654,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 3232,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 9,
    title: "Mens's Exclusive Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "120",
    discount: "25",
    brand: 2,
    ideaFor: 1,
    rating: 5,
    color: ProductColors.PINK,
    reviews: 2000,
    image: [
      {
        id: 34332,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 432121554,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 42334324,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 333245,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 10,
    title: "Limited Edition Men's Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "125",
    discount: "20",
    ideaFor: 2,
    brand: 3,
    rating: 4,
    color: ProductColors.LIGHT_PINK,
    reviews: 4356,
    image: [
      {
        id: 32222,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 3333,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 4444,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 5555,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 11,
    title: "Unisex Special Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "100",
    discount: "30",
    rating: 3,
    ideaFor: 3,
    brand: 2,
    color: ProductColors.PARROT,
    reviews: 235,
    image: [
      {
        id: 43333332343,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 323232,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 54554,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 4343232,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
  {
    id: 12,
    title: "Special Female's Watch",
    description:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    mrp: "100",
    discount: "35",
    rating: 4,
    ideaFor: 2,
    color: ProductColors.GREY,
    reviews: 4323,
    brand: 3,
    image: [
      {
        id: 43343443,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 333224324,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 5545454,
        src: "https://via.placeholder.com/130x215",
      },
      {
        id: 42332323,
        src: "https://via.placeholder.com/130x215",
      },
    ],
  },
];
export const recentOrders = [
  {
    id: "#SK231",
    customer: "Ina Hughes",
    product: "Limited Edition Men's Watch",
    date: "08-21-2020",
    paymentType: "COD",
    price: "$125",
    status: "Cancelled",
  },
  {
    id: "#SK232",
    customer: "Myrtie Ferguson",
    date: "08-12-2020",
    product: "Addida Shoes",
    paymentType: "Prepaid",
    price: "$100",
    status: "Delivered",
  },
  {
    id: "#SK233",
    customer: "Johnny Herrera",
    date: "07-30-2020",
    product: "Sleeve Jacket",
    price: "$1,020",
    paymentType: "Prepaid",
    status: "Pending",
  },
  {
    id: "#SK234",
    customer: "Ina Hughes",
    product: "Mens's Exclusive Watch",
    date: "08-21-2020",
    paymentType: "COD",
    price: "$125",
    status: "Cancelled",
  },
  {
    id: "#SK235",
    customer: "Myrtie Ferguson",
    date: "08-12-2020",
    product: "Addida Shoes",
    paymentType: "Prepaid",
    price: "$100",
    status: "Delivered",
  },
  {
    id: "#SK236",
    customer: "Johnny Herrera",
    date: "07-30-2020",
    product: "Sleeve Jacket",
    price: "$1,020",
    paymentType: "Prepaid",
    status: "Pending",
  },
  {
    id: "#SK2317",
    customer: "Ina Hughes",
    product: "Bicycle",
    date: "08-21-2020",
    paymentType: "COD",
    price: "$125",
    status: "Cancelled",
  },
  {
    id: "#SK2328",
    customer: "Myrtie Ferguson",
    date: "08-12-2020",
    product: "Addida Shoes",
    paymentType: "Prepaid",
    price: "$100",
    status: "Delivered",
  },
  {
    id: "#SK2393",
    customer: "Johnny Herrera",
    date: "07-30-2020",
    product: "Sleeve Jacket",
    price: "$1,020",
    paymentType: "Prepaid",
    status: "Pending",
  },
  {
    id: "#SK2319",
    customer: "Ina Hughes",
    product: "Bicycle",
    date: "08-21-2020",
    paymentType: "COD",
    price: "$125",
    status: "Cancelled",
  },
  {
    id: "#SK2323",
    customer: "Mens's Exclusive Watch",
    date: "08-12-2020",
    product: "Addida Shoes",
    paymentType: "Prepaid",
    price: "$100",
    status: "Delivered",
  },
  {
    id: "#SK2333",
    customer: "Johnny Herrera",
    date: "07-30-2020",
    product: "Sleeve Jacket",
    price: "$1,020",
    paymentType: "Prepaid",
    status: "Pending",
  },
  {
    id: "#SK2331",
    customer: "Ina Hughes",
    product: "Bicycle",
    date: "08-21-2020",
    paymentType: "COD",
    price: "$125",
    status: "Cancelled",
  },
  {
    id: "#SK2232",
    customer: "Myrtie Ferguson",
    date: "08-12-2020",
    product: "Addida Shoes",
    paymentType: "Prepaid",
    price: "$100",
    status: "Delivered",
  },
  {
    id: "#SK2343",
    customer: "Johnny Herrera",
    date: "07-30-2020",
    product: "Sleeve Jacket",
    price: "$1,020",
    paymentType: "Prepaid",
    status: "Pending",
  },
  {
    id: "#SK2318",
    customer: "Ina Hughes",
    product: "Bicycle",
    date: "08-21-2020",
    paymentType: "COD",
    price: "$125",
    status: "Cancelled",
  },
  {
    id: "#SK2329",
    customer: "Myrtie Ferguson",
    date: "08-12-2020",
    product: "Addida Shoes",
    paymentType: "Prepaid",
    price: "$100",
    status: "Delivered",
  },
  {
    id: "#SK2379",
    customer: "Johnny Herrera",
    date: "07-30-2020",
    product: "Sleeve Jacket",
    price: "$1,020",
    paymentType: "Prepaid",
    status: "Pending",
  },
];

export const customersData = [
  {
    id: 1,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 2,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 3,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 4,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 5,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 6,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 7,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 8,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 9,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 10,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 11,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 12,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 13,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 14,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 15,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 16,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 17,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 18,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 19,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 20,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 21,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 22,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
  {
    id: 23,
    name: "Josh Rash",
    email: "josh@exmaple.com",
    lastItem: "Apple Watch",
    lastOrder: "26 Aug",
    rating: "4.6",
    balance: "$125",
    address: "675 Grove Street Bethpage, NY 11714",
    joinDate: "23 Jan, 2015",
  },
];
export default ecommerceData;
