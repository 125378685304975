import saMessages from "../locales/it_IT.json";
import { itIT } from "@material-ui/core/locale";

const saLang = {
  messages: {
    ...saMessages,
  },
  muiLocale: itIT,
  locale: "it-IT",
};
export default saLang;
