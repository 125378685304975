const formulasTypes = {
  FORMULAS_FETCH_START: "FORMULAS_FETCH_START",
  FORMULAS_FETCH_SUCC: "FORMULAS_FETCH_SUCC",
  FORMULAS_FETCH_FAIL: "FORMULAS_FETCH_FAIL",
  FORMULAS_CREATE_SUCC: "FORMULAS_CREATE_SUCC",
  FORMULAS_CREATE_FAIL: "FORMULAS_CREATE_FAIL",
  FORMULAS_DELETE_SUCC: "FORMULAS_DELETE_SUCC",
  FORMULAS_DELETE_FAIL: "FORMULAS_DELETE_FAIL",
};

export default formulasTypes;
