const messageData = [
  {
    id: 201,
    image: "https://via.placeholder.com/150",
    message: "Hey man! Whatsapp?",
    name: "Angelina Joew",
  },
  {
    id: 202,
    image: "https://via.placeholder.com/150",
    message: "I am fine, what about you?",
    name: "John Matthew",
  },
  {
    id: 203,
    image: "https://via.placeholder.com/150",
    message: "Call me when you are free!",
    name: "George Bailey",
  },
  {
    id: 204,
    image: "https://via.placeholder.com/150",
    message: "Send your contact details!",
    name: "Maria Lee",
  },
];
export default messageData;
