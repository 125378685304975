const productionTypes = {
  PROD_REQ_FETCH_START: "PROD_REQ_FETCH_START",
  PROD_REQ_FETCH_SUCC: "PROD_REQ_FETCH_SUCC",
  PROD_REQ_FETCH_FAIL: "PROD_REQ_FETCH_FAIL",
  PROD_REQ_DELETE_SUCC: "PROD_REQ_DELETE_SUCC",
  PROD_REQ_DELETE_FAIL: "PROD_REQ_DELETE_FAIL",
  PROD_REQ_EDIT_SELECTED: "PROD_REQ_EDIT_SELECTED",
  PROD_REQ_FILTER_START: "PROD_REQ_FILTER_START",
  PROD_REQ_FILTER_SUCC: "PROD_REQ_FILTER_SUCC",
  PROD_REQ_FILTER_FAIL: "PROD_REQ_FILTER_FAIL",
  PROD_REQ_FILTER_ERASE: "PROD_REQ_FILTER_ERASE",
};

export default productionTypes;
