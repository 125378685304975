import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import UNCOD from "../../../../@crema/core/Api";
import routesConfig from "../../../../modules/routesConfig";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import VerticalNavGroup from "./VerticalNavGroup";

const Navigation = () => {
  const [pendingRequests, setPendingRequests] = useState(null);
  const [badgedRoutesConfig, setBadgedRoutesConfig] = useState(routesConfig);

  const modifyRoutes = (routesConfig, data) => {
    return routesConfig.map((item) => {
      return {
        ...item,
        children: item.children.map((item) => {
          switch (item.id) {
            case "sellRequests":
              return { ...item, count: data.sell_count };
            case "purchaseOrders":
              return { ...item, count: data.purchase_count };
            case "productionRequests":
              return { ...item, count: data.production_count };
            case "transactions":
              return { ...item, count: data.transaction_count };
            default:
              return item;
          }
        }),
      };
    });
  };
  const getPendingRequests = async () => {
    const data = await UNCOD.website.pendingRequests();
    setPendingRequests(data);
    setBadgedRoutesConfig(modifyRoutes(routesConfig, data));
  };
  useEffect(() => {
    getPendingRequests();
  }, []);

  useEffect(() => {}, [pendingRequests]);

  return (
    <List>
      {badgedRoutesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <VerticalNavGroup item={item} level={0} />}

          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} />
          )}

          {item.type === "item" && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default Navigation;
