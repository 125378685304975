import productionTypes from "./prodRequests.types";
const INITAL_STATE = {
  requests: [],
  error: "",
  filters: null,
  isLoading: false,
  pagination: null,
  requestSelected: null,
};

const prodReqReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case productionTypes.PROD_REQ_FETCH_START:
      return {
        ...state,
        isLoading: true,
        pagination: null,
        error: "",
        requests: [],
      };
    case productionTypes.PROD_REQ_FETCH_SUCC:
      return {
        ...state,
        isLoading: false,
        requests: action.payload.data,
        pagination: {
          currentPage: action.payload.current_page,
          perPage: action.payload.per_page,
          total: action.payload.total,
          from: action.payload.from,
          to: action.payload.to,
        },
      };
    case productionTypes.PROD_REQ_FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    case productionTypes.PROD_REQ_DELETE_SUCC:
      return {
        ...state,
        requests: state.requests.filter((item) => item.id != action.payload),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
      };
    case productionTypes.PROD_REQ_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case productionTypes.PROD_REQ_EDIT_SELECTED:
      return {
        ...state,
        requestSelected: action.payload,
      };
    case productionTypes.PROD_REQ_FILTER_START:
      return {
        ...state,
        filters: action.payload,
        isLoading: true,
      };
    case productionTypes.PROD_REQ_FILTER_SUCC:
      return {
        ...state,
        isLoading: false,
        requests: action.payload.data,
        pagination: {
          currentPage: action.payload.current_page,
          perPage: action.payload.per_page,
          total: action.payload.total,
          from: action.payload.from,
          to: action.payload.to,
        },
      };
    case productionTypes.PROD_REQ_FILTER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    case productionTypes.PROD_REQ_FILTER_ERASE:
      return {
        ...state,
        filters: null,
      };
    default:
      return state;
  }
};

export default prodReqReducer;
