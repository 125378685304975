import zhMessages from "../locales/zh-Hans.json";
import { zhCN } from "@material-ui/core/locale";

const ZhLan = {
  messages: {
    ...zhMessages,
  },
  muiLocale: zhCN,
  locale: "zh-Hans-CN",
};
export default ZhLan;
