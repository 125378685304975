const colorSet = [
  {
    PrimaryColor: "#0A8FDC",
    SecondaryColor: "#F04F47",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#905EAE",
    SecondaryColor: "#2D755A",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#266BB0",
    SecondaryColor: "#587527",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#F0464D",
    SecondaryColor: "#00B6EF",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#639F52",
    SecondaryColor: "#7C4D30",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#FFD955",
    SecondaryColor: "#C4476D",
    SidebarColor: "#313541",
  },

  {
    PrimaryColor: "#FD933A",
    SecondaryColor: "#5A63C8",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#03A9F4",
    SecondaryColor: "#FFC107",
    SidebarColor: "#313541",
  },
  {
    PrimaryColor: "#03A9F4",
    SecondaryColor: "#FF80AB",
    SidebarColor: "#313541",
  },

  {
    PrimaryColor: "#3F51B5",
    SecondaryColor: "#2196F3",
    SidebarColor: "#FFC107",
  },

  {
    PrimaryColor: "#9C27B0",
    SecondaryColor: "#FFCA28",
    SidebarColor: "#313541",
  },

  {
    PrimaryColor: "#673AB7",
    SecondaryColor: "#2196F3",
    SidebarColor: "#313541",
  },
];
export default colorSet;
