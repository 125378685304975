const notificationData = [
  {
    id: "1000",
    name: "Aysha Julka",
    image: "https://via.placeholder.com/150",
    message: "commented on your wall picture.",
  },
  {
    id: "1001",
    name: "Ayra Rovishi",
    image: "https://via.placeholder.com/150",
    message: "added to their stories.",
  },
  {
    id: "1002",
    name: "Sapna Awasthi",
    image: "https://via.placeholder.com/150",
    message: "commented on your wall picture.",
  },
  {
    id: "1003",
    name: "Sami Rudri",
    image: "https://via.placeholder.com/150",
    message: "tagged you in a picture.",
  },
  {
    id: "1004",
    name: "Brian Lara",
    image: "https://via.placeholder.com/150",
    message: "marked himself safe during earth quake.",
  },
  {
    id: "1005",
    name: "Rickey Ponting",
    image: "https://via.placeholder.com/150",
    message: "commented on your wall picture.",
  },
  {
    id: "1007",
    name: "Smriti Mandhana",
    image: "https://via.placeholder.com/150",
    message: "changed her wall picture.",
  },
  {
    id: "1008",
    name: "Aysha Julka",
    image: "https://via.placeholder.com/150",
    message: "changed her wall picture.",
  },
];
export default notificationData;
