import React from "react";
import { authRole } from "../../shared/constants/AppConst";

export const ecommerceConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/invoice-1",
        component: React.lazy(() => import("./Invoice1")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/invoice-2",
        component: React.lazy(() => import("./Invoice2")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/products",
        component: React.lazy(() => import("./Products")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/product_detail/:id?",
        component: React.lazy(() => import("./ProductDetail")),
      },
    ],
  },
  {
    auth: authRole.customers,
    routes: [
      {
        path: "/ecommerce/customers",
        component: React.lazy(() => import("./Customers")),
      },
    ],
  },
  {
    auth: authRole.customers,
    routes: [
      {
        path: "/ecommerce/suppliers",
        component: React.lazy(() => import("./Suppliers")),
      },
    ],
  },
  {
    auth: authRole.purchace,
    routes: [
      {
        path: "/ecommerce/purhcase/requests",
        component: React.lazy(() => import("./PurchaseRequestsNew")),
      },
    ],
  },
  {
    auth: authRole.purchace,
    routes: [
      {
        path: "/ecommerce/purhcase/request/:id",
        component: React.lazy(() => import("./PurchasePartial")),
      },
    ],
  },
  {
    auth: authRole.production,
    routes: [
      {
        path: "/ecommerce/production/requests",
        component: React.lazy(() => import("./ProductionRequests")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/checkout",
        component: React.lazy(() => import("./Checkout")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/cart",
        component: React.lazy(() => import("./Carts")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/orders",
        component: React.lazy(() => import("./Orders")),
      },
    ],
  },
  {
    auth: authRole.materials,
    routes: [
      {
        path: "/ecommerce/materials",
        component: React.lazy(() => import("./Materials")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/sales/sellReceipts",
        component: React.lazy(() => import("./Sales/SellReceipts")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/units",
        component: React.lazy(() => import("./Units")),
      },
    ],
  },
  {
    auth: authRole.production,
    routes: [
      {
        path: "/ecommerce/transactions",
        component: React.lazy(() => import("./Transactions")),
      },
    ],
  },
  {
    auth: authRole.sellRequests,
    routes: [
      {
        path: "/ecommerce/sales/sellRequests",
        component: React.lazy(() => import("./SalesRequestsNew")),
      },
    ],
  },
  {
    auth: authRole.sellRequests,
    routes: [
      {
        path: "/ecommerce/sales/sellRequest/:id",
        component: React.lazy(() => import("./SaleRequestPartial")),
      },
    ],
  },
  {
    auth: authRole.materials,
    routes: [
      {
        path: "/ecommerce/warehouse",
        component: React.lazy(() => import("./Warehouse")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/warehouseSellRequests",
        component: React.lazy(() => import("./Warehouse/SellRequests")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/warehouseSellOrders",
        component: React.lazy(() => import("./Warehouse/SellOrders")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/warehousePurchaseRequests",
        component: React.lazy(() => import("./Warehouse/PurchaseRequests")),
      },
    ],
  },
  {
    auth: authRole.customers,
    routes: [
      {
        path: "/ecommerce/managers",
        component: React.lazy(() => import("./managers")),
      },
    ],
  },
  {
    auth: authRole.engineers,
    routes: [
      {
        path: "/ecommerce/engineers",
        component: React.lazy(() => import("./Engineers")),
      },
    ],
  },
  {
    auth: authRole.shipping,
    routes: [
      {
        path: "/ecommerce/shipping",
        component: React.lazy(() => import("./Shipping")),
      },
    ],
  },
  {
    auth: authRole.customers,
    routes: [
      {
        path: "/ecommerce/departments",
        component: React.lazy(() => import("./Departments")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/warehousePurchaseOrders",
        component: React.lazy(() => import("./Warehouse/PurchaseOrders")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/warehouseSellOngoing",
        component: React.lazy(() => import("./Warehouse/OngoingSale")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/confirmation",
        component: React.lazy(() => import("./Confirmation")),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: "/ecommerce/users",
        component: React.lazy(() => import("./Users")),
      },
    ],
  },
  {
    routes: [
      {
        path: "/ecommerce/",
        component: React.lazy(() => import("./Landing")),
      },
    ],
  },
];
