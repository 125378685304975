import {
  CHANGE_READ_STATUS,
  COMPOSE_MAIL,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CONNECTION_LIST,
  GET_FOLDER_LIST,
  GET_LABEL_LIST,
  GET_MAIL_DETAIL,
  GET_MAIL_LIST,
  NULLIFY_MAIL,
  SHOW_MESSAGE,
  TOGGLE_MAIL_DRAWER,
  UPDATE_MAIL_FOLDER,
  UPDATE_MAIL_LABEL,
  UPDATE_STARRED_STATUS,
  UPDATED_MAIL_DETAIL,
} from "../../shared/constants/ActionTypes";
import Api from "../../@crema/services/ApiConfig";
import { mailListMessages } from "../../@crema/services/db/apps/mail/folderList";
import { appIntl } from "../../@crema/utility/Utils";

export const onGetMailList = (type, name, currentPage) => {
  const { messages } = appIntl();
  const page = currentPage ? currentPage : null;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/mailApp/folder/mail/List", {
      params: {
        type: type,
        name: name,
        page: page,
      },
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_MAIL_LIST, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetLabelList = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/mailApp/labels/list")
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_LABEL_LIST, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onToggleMailDrawer = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MAIL_DRAWER });
  };
};

export const onGetFolderList = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/mailApp/folders/list")
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_FOLDER_LIST, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetConnectionList = () => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get("/api/mailApp/connection/list")
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_CONNECTION_LIST, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onUpdateMailLabels = (mailIds, type) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/mailApp/update/label", { mailIds, type })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UPDATE_MAIL_LABEL, payload: data.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["message.labelUpdatedTo"],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onUpdateStarredStatus = (mailIds, status, folderName) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/mailApp/update/starred", { mailIds, status })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: UPDATE_STARRED_STATUS,
            payload: { data: data.data, folderName: folderName },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["message.starredStatus"],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onUpdateMailFolders = (mailIds, type) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/mailApp/update/folder", { mailIds, type })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UPDATE_MAIL_FOLDER, payload: data.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: mailListMessages(type),
            // payload:messages['mail.updated' />,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onUpdateReadStatus = (mailIds, status) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/mailApp/update/read", { mailIds, status })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: CHANGE_READ_STATUS, payload: data.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["message.readStatus"],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetSelectedMail = (id) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.get(`/api/mailApp/mail/`, {
      params: {
        id: id,
      },
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_MAIL_DETAIL, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onUpdateSelectedMail = (mail) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.put("/api/mailApp/mail/", { mail })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: UPDATED_MAIL_DETAIL, payload: data.data });
          dispatch({
            type: SHOW_MESSAGE,
            payload: mailListMessages(mail.folderValue),
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onComposeMail = (mail, pathname) => {
  const { messages } = appIntl();
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    Api.post("/api/mailApp/compose", { mail })
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: COMPOSE_MAIL,
            payload: { data: data.data, pathname: pathname },
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages["mail.sentSuccessfully"],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages["message.somethingWentWrong"],
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onNullifyMail = () => {
  return {
    type: NULLIFY_MAIL,
  };
};
