export const authRole = {
  sellRequests: ["admin", "secretarial", "sales_manager"],
  production: [
    "admin",
    "sales_manager",
    "warehouse_keeper",
    "production_manager",
  ],
  purchace: ["admin", "secretarial", "warehouse_keeper", "purchases_manager"],
  materials: ["admin", "warehouse_keeper"],
  engineers: ["admin", "secretarial"],
  customers: ["admin", "secretarial"],
  shipping: ["admin", "secretarial"],
  employee: ["admin", "secretarial"],
  user: ["admin"],
};

export const defaultUser = {
  displayName: "Fathee",
  email: "Fathe@uncood.com",
  token: "access-token",
  role: "user",
  photoURL: "https://via.placeholder.com/150",
};
export const initialUrl = "/ecommerce"; // this url will open after login
