import enMessages from "../locales/en_US.json";
import { enUS } from "@material-ui/core/locale";

const EnLang = {
  messages: {
    ...enMessages,
  },
  muiLocale: enUS,
  locale: "en-US",
};
export default EnLang;
