import formulasTypes from "./formulas.types";

const INITAL_STATE = {
  formulas: [],
  error: "",
  isLoading: false,
  materialId: -1,
};

const formulasReducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case formulasTypes.FORMULAS_FETCH_START:
      return {
        ...state,
        isLoading: true,
      };
    case formulasTypes.FORMULAS_FETCH_SUCC:
      return {
        ...state,
        error: "",
        formulas: action.payload.formulas,
        materialId: action.payload.id,
        isLoading: false,
      };

    case formulasTypes.FORMULAS_FETCH_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case formulasTypes.FORMULAS_CREATE_SUCC:
      return {
        ...state,
        formulas: [
          ...state.formulas,
          {
            ...action.payload.formula.formulas[0],
            raw_material: action.payload.material,
            created_at: new Date().toISOString(),
          },
        ],
      };
    case formulasTypes.FORMULAS_CREATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case formulasTypes.FORMULAS_DELETE_SUCC: {
      return {
        ...state,
        formulas: state.formulas.filter((item) => item.id !== action.payload),
      };
    }
    case formulasTypes.FORMULAS_DELETE_FAIL: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default formulasReducer;
