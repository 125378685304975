const { default: purchaseRequestsTypes } = require("./purReq.types");

const INITIAL_STATE = {
  requests: [],
  isLoading: false,
  error: "",
  pagination: null,
  requestToUpdate: null,
};

const purReqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case purchaseRequestsTypes.PUR_REQ_FETCH_START:
      return {
        ...state,
        isLoading: true,
      };
    case purchaseRequestsTypes.PUR_REQ_FETCH_SUCC:
      return {
        ...state,
        isLoading: false,
        requests: action.payload.data,
        pagination: {
          to: action.payload.to,
          from: action.payload.from,
          total: action.payload.total,
          perPage: action.payload.per_page,
          current: action.payload.current_page,
        },
      };
    case purchaseRequestsTypes.PUR_REQ_FETCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case purchaseRequestsTypes.REQUEST_UPDATE:
      return {
        ...state,
        requestToUpdate: action.payload,
      };
    default:
      return state;
  }
};

export default purReqReducer;
