import {
  FETCH_START,
  FETCH_SUCCESS,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from "../../shared/constants/ActionTypes";
import jwtAxios from "../../@crema/services/auth/jwt-auth/jwt-api";
import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { AuthType } from "../../shared/constants/AppEnums";
import { defaultUser } from "../../shared/constants/AppConst";
import UNCOD from "../../@crema/core/Api";
export const onJwtUserSignUp = ({ email, password, name }) => {
  console.log("email, password", { email, password, name });
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, name, password };
    try {
      const res = await jwtAxios.post("users", body);
      localStorage.setItem("token", res.data.token);
      dispatch(setJWTToken(res.data.token));
      dispatch(loadJWTUser());
    } catch (err) {
      console.log("error!!!!", err.response.data.error);
      dispatch(fetchError(err.response.data.error));
    }
  };
};

export const onJwtSignIn = ({ email, password }) => {
  return async (dispatch) => {
    dispatch(fetchStart());
    const body = { email, password };
    try {
      const res = await UNCOD.website.users.login(body);
      console.log(res);
      if (res?.data?.access_token) {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("user", JSON.stringify(res.data));
        dispatch(setJWTToken(res.data.access_token));
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: {
            authType: AuthType.JWT_AUTH,
            displayName: res.data.name,
            role: res.data.role.type,
          },
        });
        dispatch(fetchSuccess());
      } else throw res;
    } catch (err) {
      console.log("error!!!!", err);
      dispatch(fetchError(err));
    }
  };
};

export const loadJWTUser = () => {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.get("/auth");
      dispatch(fetchSuccess());
      console.log("res.data", res.data);
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: AuthType.JWT_AUTH,
          displayName: res.data.name,
          email: res.data.email,
          role: defaultUser.role,
          token: res.data._id,
          photoURL: res.data.avatar,
        },
      });
    } catch (err) {
      console.log("error!!!!", err.response.error);
      dispatch(fetchError(err.response.error));
    }
  };
};

export const setJWTToken = (token) => {
  return async (dispatch) => {
    dispatch({
      type: SET_AUTH_TOKEN,
      payload: token,
    });
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: SIGNOUT_AUTH_SUCCESS });
    dispatch({ type: FETCH_SUCCESS });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };
};
