import axios from "axios";

export const server = "https://backend.uncod.net";
export const api = "/api/dev";

const UNCOD = axios.create({
  baseURL: server,
  timeOut: 20000,
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

UNCOD.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

const defaultApi = {
  website: {
    pendingRequests: async () => {
      try {
        const { data } = await UNCOD.get(`${api}/pendingRequestsCount`);
        return data.data;
      } catch (e) {
        const { data } = e.response;
        return data.message;
      }
    },
    units: {
      getAll: async () => {
        try {
          const { data } = await UNCOD.get(`${api}/units`);
          return data.data;
        } catch (e) {
          const { data } = e.response;
          return data.message;
        }
      },

      add: async (name) => {
        try {
          const { data } = await UNCOD.post(`${api}/units`, { name });
          return data.message;
        } catch (e) {
          const { data } = e.response;
          return data.message;
        }
      },
      edit: async (name, id) => {
        try {
          const { data } = await UNCOD.put(`${api}/units/${id}`, { name });
          return data.message;
        } catch (e) {
          const { data } = e.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/units/${id}`);
          return data.message;
        } catch (e) {
          const { data } = e.response;
          return data.message;
        }
      },
    },
    materials: {
      getAll: async (page = 0, name = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/materials?page=${page}&filter[name]=${name}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      getAllByName: async (page = 0, name = "", type = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/materials?filter[name]=${name}&page=${page}&filter[type]=${type}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return err;
        }
      },

      get: async (id = 1) => {
        try {
          const { data } = await UNCOD.get(`${api}/materials/${id}`);
          return data;
        } catch (err) {
          const response = err.response.data;
          return err;
        }
      },
      create: async ({
        name,
        type,
        unit_id,
        code,
        barcode,
        manufactured_quantity,
        note,
      }) => {
        try {
          const response = await UNCOD.post(`${api}/materials`, {
            name,
            type,
            unit_id,
            code,
            barcode,
            manufactured_quantity,
            note,
          });
          return response.data;
        } catch (err) {
          const response = err.response.data;
          return response;
        }
      },
      update: async ({
        name,
        type,
        unit_id,
        code,
        barcode,
        manufactured_quantity,
        note,
        id,
      }) => {
        try {
          const response = await UNCOD.put(`${api}/materials/${id}`, {
            name,
            type,
            unit_id,
            code,
            barcode,
            manufactured_quantity,
            note,
          });
          return response.data;
        } catch (err) {
          const response = err.response.data;
          return response;
        }
      },
      delete: async (id) => {
        try {
          const response = await UNCOD.delete(`${api}/materials/${id}`);
          return response;
        } catch (err) {
          const response = err.response.data;
          return response;
        }
      },
      filter: async (page = 1, searchQuery) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/materials?page=${page}&filter[name]=${searchQuery}`
          );
          return data;
        } catch (err) {
          const response = err.response.data;
          return response;
        }
      },
    },
    formulas: {
      getById: async (id) => {
        try {
          const { data } = await UNCOD.get(`${api}/materials/${id}/formulas`);
          return data;
        } catch (e) {
          return e.message;
        }
      },
      createFormula: async (id, formula) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/materials/${id}/formulas`,
            formula
          );
          return data;
        } catch (e) {
          return e.response.data;
        }
      },
      deleteFormula: async (materialId, formulaId) => {
        try {
          const { data } = await UNCOD.delete(
            `${api}/materials/${materialId}/formulas/${formulaId}`
          );
          return data;
        } catch (e) {
          return e.response.data;
        }
      },
      updateFormula: async (materialId, formulaId, formula) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/materials/${materialId}/formulas/${formulaId}`,
            formula
          );
          return data;
        } catch (e) {
          return e.response.data;
        }
      },
    },
    transactions: {
      getAll: async (type, page = 0) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/transactions?type=${type}&page=${page}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async ({ material_id, warehouse_id, type, quantity, note }) => {
        try {
          const { data } = await UNCOD.post(`${api}/transactions`, {
            material_id: material_id,
            warehouse_id: warehouse_id,
            type: type,
            quantity: quantity,
            note: note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      changeStatus: async ({ status, id }) => {
        try {
          const { data } = await UNCOD.patch(`${api}/transactions/${id}`, {
            status: status,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      update: async ({
        material_id,
        warehouse_id,
        type,
        quantity,
        note,
        id,
      }) => {
        try {
          const { data } = await UNCOD.put(`${api}/transactions/${id}`, {
            material_id: material_id,
            warehouse_id: warehouse_id,
            type: type,
            quantity: quantity,
            note: note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/transactions/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    warehouses: {
      getAll: async (page = 0) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/warehouses?page=${page}&include=materials`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      get: async (id = 0) => {
        try {
          const response = await UNCOD.get(
            `${api}/warehouses/${id}?include=materials`
          );
          const { data } = response.data;
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async ({ name, location, note }) => {
        try {
          const { data } = await UNCOD.post(`${api}/warehouses`, {
            name,
            location,
            note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      update: async ({ name, location, note, id }) => {
        try {
          const { data } = await UNCOD.put(`${api}/warehouses/${id}`, {
            name,
            location,
            note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/warehouses/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      relations: async (id, mode) => {
        try {
          const { data } = await UNCOD.get(`${api}/warehouses/${id}/${mode}`);
          return data;
        } catch (err) {
          const response = err.response.data;
          return response;
        }
      },
      appendMaterial: async ({ matId, quantity, id }) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/warehouses/${id}/add-material`,
            {
              material_id: matId,
              quantity,
            }
          );

          return data;
        } catch (err) {
          const { data } = err.response;
          return data;
        }
      },
      removeMaterial: async ({ matId, quantity, id }) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/warehouses/${id}/remove-material`,
            {
              material_id: matId,
              quantity,
            }
          );

          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      transformMaterial: async ({ matId, quantity, id, wareId }) => {
        try {
          const result = await UNCOD.post(
            `${api}/warehouses/${id}/move-material`,
            {
              new_warehouse_id: wareId,
              material_id: matId,
              quantity: quantity,
            }
          );

          return result.data;
        } catch (err) {
          const { data } = err.response;
          return data;
        }
      },
    },
    sales: {
      sellReceipts: {
        getAll: async (page = 0) => {
          try {
            const { data } = await UNCOD.get(
              `${api}/sell-receipts?page=${page}`
            );
            return data;
          } catch (err) {
            const response = err.response.data;
            return response;
          }
        },
        create: async ({
          number,
          receiver,
          handler,
          deliveryDate,
          sell_request_id,
          shipping_company_id,
        }) => {
          try {
            const { data } = await UNCOD.post(`${api}/sell-receipts`, {
              number,
              receiver,
              handler,
              deliveryDate,
              sell_request_id,
              shipping_company_id,
            });
            return data.message;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
        delete: async (id = 0) => {
          try {
            const { data } = await UNCOD.delete(`${api}/sell-receipts/${id}`);
            return data.message;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
        update: async ({
          number,
          receiver,
          handler,
          deliveryDate,
          sell_request_id,
          shipping_company_id,
          id,
        }) => {
          try {
            const { data } = await UNCOD.put(`${api}/sell-receipts/${id}`, {
              number,
              receiver,
              handler,
              deliveryDate,
              sell_request_id,
              shipping_company_id,
            });
            return data.message;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
      },
      sellRequests: {
        getAll: async (page = 0) => {
          try {
            const { data } = await UNCOD.get(
              `${api}/sell-requests?page=${page}`
            );
            return data;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
        findByuNumber: async (page = 0, number) => {
          try {
            const { data } = await UNCOD.get(
              `${api}/sell-requests?page=${page}&filter[number]=${number}`
            );
            return data;
          } catch (err) {
            const response = err.response.data;
            return response;
          }
        },
        getReactionaries: async (id) => {
          try {
            const { data } = await UNCOD.get(
              `${api}/sell-requests/${id}/reactionaries`
            );
            return data.data;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
        addReactionaries: async (id, mats) => {
          try {
            const { data } = await UNCOD.post(
              `${api}/sell-requests/${id}/reactionaries`,
              mats
            );
            return data.message;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
        filter: async (page = 0, filters) => {
          try {
            let uri = "";
            Object.keys(filters).forEach((item, idx) => {
              if (
                item === "delivery_time_in_month_between" ||
                item == "delivery_time_in_day_between" ||
                item == "quantity"
              ) {
                if (Object.values(filters)[idx] !== null)
                  uri += `&range[${item}]=${Object.values(filters)[idx].low}-${
                    Object.values(filters)[idx].high
                  }`;
              } else {
                Object.values(filters)[idx].forEach((subFilter) => {
                  uri += `&filter[${item}]=${subFilter}`;
                });
              }
            });
            const { data } = await UNCOD.get(
              `${api}/sell-requests?page=${page}${uri}`
            );
            return data;
          } catch (err) {
            const response = err.response.data;
            return response;
          }
        },
        get: async (id = 1) => {
          try {
            const { data } = await UNCOD.get(`${api}/sell-requests/${id}`);
            return data;
          } catch (err) {
            const { data } = err.response;
            return data.message;
          }
        },
        create: async (request) => {
          try {
            const { data } = await UNCOD.post(`${api}/sell-requests`, request);
            return data;
          } catch (err) {
            console.log(err.response.data.message);
            return err.response.data;
          }
        },
        approve: async (id) => {
          try {
            const { data } = await UNCOD.put(
              `${api}/sell-requests/${id}/approve`
            );
            return data;
          } catch (err) {
            console.log(err.response.data.message);
            return err.response.data;
          }
        },
        decline: async (id) => {
          try {
            const { data } = await UNCOD.delete(
              `${api}/sell-requests/${id}/decline`
            );
            return data;
          } catch (err) {
            console.log(err.response.data.message);
            return err.response.data;
          }
        },
        notApprove: async (id) => {
          try {
            const { data } = await UNCOD.put(
              `${api}/sell-requests/${id}/notApprove`
            );
            return data;
          } catch (err) {
            console.log(err.response.data.message);
            return err.response.data;
          }
        },
        delivre: async (id) => {
          try {
            const { data } = await UNCOD.put(
              `${api}/sell-requests/${id}/deliver`
            );
            return data;
          } catch (err) {
            console.log(err.response.data.message);
            return err.response.data;
          }
        },
        ship: async (id) => {
          try {
            const { data } = await UNCOD.put(`${api}/sell-requests/${id}/ship`);
            return data;
          } catch (err) {
            return err.response.data;
          }
        },
        edit: async (id, request) => {
          try {
            const { data } = await UNCOD.put(
              `${api}/sell-requests/${id}`,
              request
            );
            return data;
          } catch (err) {
            return err.response.data;
          }
        },
        delete: async (id) => {
          try {
            const { data } = await UNCOD.delete(`${api}/sell-requests/${id}`);
            return data;
          } catch (err) {
            return err.response.data;
          }
        },
        getPartials: async (id) => {
          try {
            const { data } = await UNCOD.get(
              `${api}/sell-requests/${id}/partial`
            );
            return data.data;
          } catch (e) {
            return e.response.data;
          }
        },
        addPartial: async (id, body) => {
          try {
            const { data } = await UNCOD.post(
              `${api}/sell-requests/${id}/partial`,
              body
            );
            return data.message;
          } catch (e) {
            return e.response.data.message;
          }
        },
        deletePartial: async (id, partialId) => {
          try {
            const { data } = await UNCOD.delete(
              `${api}/sell-requests/${id}/partial/${partialId}`
            );
            return data?.message;
          } catch (e) {
            return e.response.data.message;
          }
        },
        editPartial: async (id, partialId, body) => {
          try {
            const { data } = await UNCOD.put(
              `${api}/sell-requests/${id}/partial/${partialId}`,
              body
            );
            return data.message;
          } catch (e) {
            return e.response.data.message;
          }
        },
      },
    },
    customers: {
      getAll: async (page = 0, query = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/customers?page=${page}&filter[name]=${query}`
          );
          return data;
        } catch (err) {
          const response = err.response.message;
          return response;
        }
      },
      create: async ({ name, address, phone, note }) => {
        try {
          const { data } = await UNCOD.post(`${api}/customers`, {
            name,
            address,
            phone,
            note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      update: async ({ name, address, phone, note, id }) => {
        try {
          const { data } = await UNCOD.put(`${api}/customers/${id}`, {
            name,
            address,
            phone,
            note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/customers/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    suppliers: {
      getAll: async (page = 0, query = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/suppliers?page=${page}&filter[name]=${query}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async ({ name, address, phone, note }) => {
        try {
          const { data } = await UNCOD.post(`${api}/suppliers`, {
            name,
            address,
            phone,
            note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      update: async ({ name, address, phone, note, id }) => {
        try {
          const { data } = await UNCOD.put(`${api}/suppliers/${id}`, {
            name,
            address,
            phone,
            note,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id = 0) => {
        try {
          const { data } = await UNCOD.delete(`${api}/suppliers/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    purchaseRequests: {
      getAll: async (page = 0, query = "", filter = []) => {
        try {
          let uri = "";
          filter.forEach((item) => {
            uri += `&filter[${item.name}]=${item.value}`;
          });
          const { data } = await UNCOD.get(
            `${api}/purchase-requests?page=${page}&filter[number]=${query}${uri}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      getReactionaries: async (id) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/purchase-requests/${id}/reactionaries`
          );
          return data.data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      addReactionaries: async (id, mats) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/purchase-requests/${id}/reactionaries`,
            mats
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      getPartials: async (id) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/purchase-requests/${id}/partial`
          );
          return data.data;
        } catch (e) {
          return e.response.data;
        }
      },
      addPartial: async (id, body) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/purchase-requests/${id}/partial`,
            body
          );
          return data.message;
        } catch (e) {
          return e.response.data.message;
        }
      },
      deletePartial: async (id, partialId) => {
        try {
          const { data } = await UNCOD.delete(
            `${api}/purchase-requests/${id}/partial/${partialId}`
          );
          return data?.message;
        } catch (e) {
          return e.response.data.message;
        }
      },
      editPartial: async (id, partialId, body) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}/partial/${partialId}`,
            body
          );
          return data.message;
        } catch (e) {
          return e.response.data.message;
        }
      },
      get: async (id = 1) => {
        try {
          const { data } = await UNCOD.get(`${api}/purchase-requests/${id}`);
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async (request) => {
        try {
          const { data } = await UNCOD.post(`${api}/purchase-requests`, {
            ...request,
          });
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      approve: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}/approve`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      ship: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}/ship`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      decline: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}/decline`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      reject: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}/reject`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      deliver: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}/deliver`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      update: async (id, body) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/purchase-requests/${id}`,
            body
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/purchase-requests/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    managers: {
      getAll: async (page = 0, query = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/managers?page=${page}&filter[name]=${query}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async (name) => {
        try {
          const { data } = await UNCOD.post(`${api}/managers`, { name });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      edit: async (id, name) => {
        try {
          const { data } = await UNCOD.put(`${api}/managers/${id}`, { name });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/managers/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    departments: {
      getAll: async () => {
        try {
          const { data } = await UNCOD.get(`${api}/departments`);
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async (name) => {
        try {
          const { data } = await UNCOD.post(`${api}/departments`, { name });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      edit: async (id, name) => {
        try {
          const { data } = await UNCOD.put(`${api}/departments/${id}`, {
            name,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id, name) => {
        try {
          const { data } = await UNCOD.delete(`${api}/departments/${id}`, {
            name,
          });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    engineers: {
      getAll: async (page = 0, query = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/engineers?page=${page}&filter[name]=${query}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async (name) => {
        try {
          const { data } = await UNCOD.post(`${api}/engineers`, { name });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      edit: async (id, name) => {
        try {
          const { data } = await UNCOD.put(`${api}/engineers/${id}`, { name });
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/engineers/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    shippingCompany: {
      getAll: async (page, query) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/shipping-companies?page=${page}&filter[name]=${query}`,
            { headers: {} }
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(
            `${api}/shipping-companies/${id}`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async (shipp) => {
        try {
          const { data } = await UNCOD.post(`${api}/shipping-companies`, shipp);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      edit: async (id, shipp) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/shipping-companies/${id}`,
            shipp
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    productionRequests: {
      getAll: async (page = 0, query = "", filters = null) => {
        try {
          console.log(filters);
          let uriQuery = "";
          if (filters) {
            const { status, material_id, shipping_company_id } = filters;
            status.forEach((item) => {
              uriQuery += `&filter[status]=${item}`;
            });
            material_id.forEach((item) => {
              uriQuery += `&filter[material_id]=${item}`;
            });
            shipping_company_id.forEach((item) => {
              uriQuery += `&filter[shipping_company_id]=${item}`;
            });
          }
          const { data } = await UNCOD.get(
            `${api}/production-requests?page=${page}&filter[number]=${query}${uriQuery}`
          );
          return data;
        } catch (e) {
          return e.message;
        }
      },
      getReactionaries: async (id) => {
        try {
          const { data } = await UNCOD.get(
            `${api}/production-requests/${id}/reactionaries`
          );
          return data.data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      addReactionaries: async (id, mats) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/production-requests/${id}/reactionaries`,
            mats
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(
            `${api}/production-requests/${id}`
          );
          return data.message;
        } catch (e) {
          return e.response.data.message;
        }
      },
      create: async (request) => {
        try {
          const { data } = await UNCOD.post(
            `${api}/production-requests`,
            request
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      edit: async (id, request) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/production-requests/${id}`,
            request
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      approve: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/production-requests/${id}/approve`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      reject: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/production-requests/${id}/reject`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      reject: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/production-requests/${id}/reject`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      ship: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/production-requests/${id}/ship`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      deliver: async (id) => {
        try {
          const { data } = await UNCOD.put(
            `${api}/production-requests/${id}/deliver`
          );
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    users: {
      login: async (body) => {
        try {
          const { data } = await UNCOD.post(`${api}/auth/login`, body);
          UNCOD.defaults.headers.Authorization =
            "Bearer " + data?.data?.access_token;
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      getAll: async (page = 0, query = "") => {
        try {
          const { data } = await UNCOD.get(
            `${api}/users?page=${page}&query=${query}`
          );
          return data;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      delete: async (id) => {
        try {
          const { data } = await UNCOD.delete(`${api}/users/${id}`);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      create: async (user) => {
        try {
          const { data } = await UNCOD.post(`${api}/users`, user);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
      edit: async (id, user) => {
        try {
          const { data } = await UNCOD.put(`${api}/users/${id}`, user);
          return data.message;
        } catch (err) {
          const { data } = err.response;
          return data.message;
        }
      },
    },
    roles: {
      getAll: async () => {
        try {
          const { data } = await UNCOD.get(`${api}/roles`);
          return data;
        } catch (e) {
          const { data } = e.response;
          return data.message;
        }
      },
    },
  },
};

export default defaultApi;
