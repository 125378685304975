import { purchasePartialTypes } from "./purchasePartial.types"
const INITAIL_STATE = {
  isLoading: false,
  data: [],
  isError: false,
  error: null,
}

export const purchasePartialReducer = (
  state = INITAIL_STATE,
  { type, payload }
) => {
  switch (type) {
    case purchasePartialTypes.PARTIAL_FETCH_START:
      return {
        ...state,
        isLoading: true,
        error: null,
        isError: false,
      }
    case purchasePartialTypes.PARTIAL_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
      }
    case purchasePartialTypes.PARTIAL_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isError: true,
      }
    default:
      return state
  }
}
