export const staffList = [
  {
    id: 501,
    name: "Johnson",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 502,
    name: "Joe Root",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 503,
    name: "Monty Panesar",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 504,
    name: "Darren Gough",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 505,
    name: "Andy Caddick",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 506,
    name: "Marcus Vaughan",
    image: "https://via.placeholder.com/150",
  },
];
