import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Setting";
import MailApp from "./MailApp";
import Common from "./Common";
import Editors from "./Editors";
import ToDoApp from "./ToDoApp";
import Dashboard from "./Dashboard";
import Gallery from "./Gallery";
import UserList from "./UserList";
import Ecommerce from "./Ecommerce";
import ContactApp from "./ContactApp";
import ScrumboardApp from "./ScrumboardApp";
import Auth from "./Auth";
import ChatApp from "./ChatApp";
import Wall from "./Wall";
import purReqReducer from "redux/purchaceRequests/purReq.reducer";
import prodReqReducer from "redux/productionRequests/prodReq.reducer";
import formulasReducer from "redux/formulas/formulas.reducer";
import { partialReciptReducer } from "redux/partialRecipt/partialRecipt.reducer";
import { purchasePartialReducer } from "redux/purchasePartial/purchasePartial.reducer";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    mailApp: MailApp,
    dashboard: Dashboard,
    common: Common,
    editors: Editors,
    todoApp: ToDoApp,
    gallery: Gallery,
    userList: UserList,
    ecommerce: Ecommerce,
    contactApp: ContactApp,
    scrumboardApp: ScrumboardApp,
    chatApp: ChatApp,
    wall: Wall,
    purchaseRequests: purReqReducer,
    productionRequests: prodReqReducer,
    formulas: formulasReducer,
    partialReciptReducer : partialReciptReducer,
    purchasePartial : purchasePartialReducer
  });
export default reducers;
