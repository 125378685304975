import { authRole } from "../shared/constants/AppConst";

const routesConfig = [
  {
    id: "app",
    title: "Application",
    messageId: "sidebar.application",
    type: "group",
    children: [
      {
        id: "materials",
        title: "Materials",
        messageId: "sidebar.materialTable",
        type: "item",
        icon: "table",
        url: "/ecommerce/materials",
        auth: authRole.materials,
      },
      {
        id: "warehouse",
        title: "warehouse",
        messageId: "warehouse.warehouses",
        type: "item",
        icon: "table",
        url: "/ecommerce/warehouse",
        auth: authRole.materials,
      },
      {
        id: "sellRequests",
        title: "SellRequests",
        messageId: "sales.sellRequests",
        type: "item",
        icon: "table",
        url: "/ecommerce/sales/sellRequests",
        auth: authRole.sellRequests,
      },
      {
        id: "purchaseOrders",
        title: "PurchaseOrders",
        messageId: "warehouse.warehousePurchaseRequests",
        type: "item",
        icon: "table",
        url: "/ecommerce/purhcase/requests",
        auth: authRole.purchace,
      },
      {
        id: "productionRequests",
        title: "ProductionRequests",
        messageId: "warehouse.productionRequests",
        type: "item",
        icon: "table",
        url: "/ecommerce/production/requests",
        auth: authRole.production,
      },
      {
        id: "transactions",
        title: "Transactions",
        messageId: "transactions",
        type: "item",
        icon: "table",
        url: "/ecommerce/transactions",
        auth: authRole.production,
      },
      {
        id: "units",
        title: "units",
        messageId: "material.units",
        type: "item",
        icon: "table",
        url: "/ecommerce/units",
        auth: authRole.employee,
      },
      {
        id: "managers",
        title: "managers",
        messageId: "information.managers",
        type: "item",
        icon: "table",
        url: "/ecommerce/managers",
        auth: authRole.employee,
      },
      {
        id: "suppliers",
        title: "Suppliers",
        messageId: "information.supplier",
        type: "item",
        icon: "table",
        url: "/ecommerce/suppliers",
        auth: authRole.employee,
      },
      {
        id: "departments",
        title: "departments",
        messageId: "information.department",
        type: "item",
        icon: "table",
        url: "/ecommerce/departments",
        auth: authRole.employee,
      },
      {
        id: "engineers",
        title: "engineers",
        messageId: "information.engineer",
        type: "item",
        icon: "table",
        url: "/ecommerce/engineers",
        auth: authRole.engineers,
      },
      {
        id: "shipping",
        title: "shipping",
        messageId: "information.shipping",
        type: "item",
        icon: "table",
        url: "/ecommerce/shipping",
        auth: authRole.shipping,
      },
      {
        id: "customers",
        title: "customers",
        messageId: "sidebar.ecommerce.customers",
        type: "item",
        icon: "table",
        url: "/ecommerce/customers",
        auth: authRole.customers,
      },
      {
        id: "users",
        title: "users",
        messageId: "sidebar.ecommerce.users",
        type: "item",
        icon: "table",
        url: "/ecommerce/users",
        auth: authRole.user,
      },
    ],
  },
];
export default routesConfig;
